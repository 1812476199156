import React, { useState, useEffect } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import Col from 'react-bootstrap/Col';

import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Stack from 'react-bootstrap/Stack';

import TableForm from './TableForm'
import { setLoading, getLoading} from "../slices/loading";

import { useNavigate } from "react-router-dom";


import Card from 'react-bootstrap/Card';
import { getCutOff, add_table,getTables,save_api_tables,save_api_order, remove_seat, clean_tables, get_api_tables, set_table } from "../slices/ticket";
import { setMessage, showMessage } from "../slices/message";



function Ticketing() {
  const [successful, setSuccessful] = useState(false);
  const [isCutOff, setIsCutOff] = useState(false);

  const dispatch = useDispatch()
  const navigate = useNavigate();
  
  const tables = useSelector(getTables)
  const cutOff = useSelector(getCutOff)

  const newDate = new Date()

  const cutOffDate = new Date(cutOff)
  

  useEffect(() => {
    console.log("tests");
  }, [tables]);

  useEffect(() => {
    if (newDate < new Date(cutOff)){
        setIsCutOff(true)
    }

  }, [cutOff]);

  const handleCleanTables = () =>{
    dispatch(setLoading(true))
    dispatch(clean_tables())
    .unwrap()
    .then(() => { 
                dispatch(setLoading(false))
                dispatch(setMessage("Removed empty tables"))
                dispatch(get_api_tables()).unwrap()
                    .then((data) => {
                        console.log(data)
                        dispatch(set_table(data))
                        
                    })
                    .catch((error) => {

                        console.log(error)

                    });
              
                dispatch(showMessage(true))

            })
          .catch((error) => {
            console.log(error)
            dispatch(setLoading(false))
            dispatch(setMessage("Error Removing Tables. Please try again"))
            dispatch(showMessage(true))
          });
  }

  const handleOrderSave = () => {

    dispatch(setLoading(true))
    dispatch(save_api_tables(tables))
    .unwrap()
          .then(() => {
            dispatch(save_api_order())
            .unwrap()
            .then(() => { 
                dispatch(setLoading(false))
                dispatch(setMessage("Order Created"))
                dispatch(showMessage(true))

            })
            
            
          })
          .catch((error) => {
            console.log(error)
            dispatch(setLoading(false))
            dispatch(setMessage("Error creating Orders. Please try again"))
            dispatch(showMessage(true))
          });

  }

  const handleSave = () => {
    dispatch(setLoading(true))
    dispatch(save_api_tables(tables))
    .unwrap()
          .then(() => {
            dispatch(setLoading(false))
            dispatch(setMessage("Tables Saved"))
            dispatch(showMessage(true))
            
          })
          .catch((error) => {
            dispatch(setLoading(false))
            dispatch(setMessage("Error while savings tables. Please check for any errors in the seating."))
            dispatch(showMessage(true))
          });
  }

  return (
    <Container>
        
        <Stack gap={3}>
            <Row>
                <Col>
                    <Stack gap={3}>
                    
                    
                    <Card>
                        <Card.Body>
                            <h3> ACRA Ticketing</h3>
                            <p>
                                Welcome to ACRA Award ticketing website.
                                <strong>To start add a new table. </strong>
                                <ul>
                                    <li>Each table allows for <strong>10</strong> seats.</li>
                                    <li>Fill out as many seats on each table.</li>
                                    <li>Any seats that are not filled in that are not filled in on a table will not be charged.</li>
                                    <li>If any seats are not filled on a table, there will be a high chance that those extra seats will be filled by another company.</li>
                                    <li>You can keep adding tables and seats and pay for additional seats. <br/> <strong> New tables or seats cannot be added after {cutOffDate.toString() } </strong></li>
                                    <li>Details of each seat can be changed. </li>
                                    <li>Table number are not table numbers for the night.</li>
                                    
                                    <li>If you wish import from Excel to add new tables, click <strong onClick={()=>navigate('/import')}>here. </strong></li>
                                    <li><strong style={{color:"red"}}> We will try out best to keep tables from the same network/company together but there will be chances where we cannot.</strong></li>
                                    <li><strong style={{color:"red"}}> Seats are not reserved until an order is created. If you do not know the information for the seats, please use the prefill button.</strong></li>
                                </ul>
                            </p>
                            
                            <p> Please remember to click <strong>save</strong> if you make any changes.</p>
                        </Card.Body>

                    </Card>
                    <Card>  
                        
                        <Card.Body>
                            {
                                isCutOff ? 
                                <>
                                    <Button variant="primary" onClick={()=>dispatch(add_table())}>Add Table</Button>{' '}
                                </>     : null
                                
                            }
                            <Button variant="success" onClick={()=>handleSave()} >Save</Button>{' '}
                            <Button variant="info" onClick={()=>handleCleanTables()} >Clear Unused Tables</Button>{' '}
                            <Button variant="light" onClick={()=>navigate('/order')} >Pay for Tickets</Button>{' '}    
                        </Card.Body>
                    </Card>
                    </Stack>
                </Col>
            </Row>
            <Row>
                <Col>
                    { 
                        tables.map((table, i ) => {
                            return(
                                <TableForm key={i} table={table} />
                            )
                        })
                    }
                </Col>
            </Row>
            <Row>
                <Col>
                    <Stack gap={3}>
                    <Card>  
                        
                        <Card.Body>
                        {
                            isCutOff ? 
                            <>
                                <Button variant="primary" onClick={()=>dispatch(add_table())}>Add Table</Button>{' '}
                            </> : <></>
                        }
                            <Button variant="success" onClick={()=>handleSave()} >Save</Button>{' '}
                            <Button variant="info" onClick={()=>handleCleanTables()} >Clear Unused Tables</Button>{' '}
                            <Button variant="light" onClick={()=>navigate('/order')} >Pay for Tickets</Button>{' '}    
                        </Card.Body>
                    </Card>
                    </Stack>
                </Col>
            </Row>
      </Stack>
    </Container>
   
  )
}

export default Ticketing
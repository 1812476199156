import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "./message";

import AuthService from "../services/auth.service";
import UserService from "../services/user.service";

const user = JSON.parse(localStorage.getItem("user"));

export const register = createAsyncThunk(
  "auth/register",
  async (data, thunkAPI) => {
    try {
      const response = await AuthService.register(data);
      thunkAPI.dispatch(setMessage(response.data.message));
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data)
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const login = createAsyncThunk(
  "auth/login",
  async ({ email, password }, thunkAPI) => {
    try {
      const data = await AuthService.login(email, password);
      return { user: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data )
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const profile = createAsyncThunk(
  "auth/profile",
  async (input, thunkAPI) => {
    try {
      const data = await UserService.getUserBoard();
      return data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data )
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const save_api_profile = createAsyncThunk(
  "auth/save_api_profile",
  async (input, thunkAPI) => {
    try {
      const data = await UserService.saveUserProfile(input);
      return data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data )
      console.log(error)
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue(message);
    }
  }
);




const initialState = {
  isLoading: false,
  error: null,
  isAuthenticated: localStorage.getItem('acra-ticket-user') ? true : false,
  user: null,
  profile: null,
}

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers:{
    save_profile: (state, action) =>{
      state.profile = action.payload
    },
    logout: (state) =>{
        AuthService.logout();
        state.isAuthenticated = false
        state.user = null
        state.profile = null
    },
    
  },
  extraReducers: {
    [register.fulfilled]: (state, action) => {
      state.isAuthenticated = false;
    },
    [register.rejected]: (state, action) => {
      state.isAuthenticated = false;
    },
    [login.fulfilled]: (state, action) => {
      state.isAuthenticated = true;
      state.user = action.payload.user;
    },
    [login.rejected]: (state, action) => {
      state.isAuthenticated = false;
      state.user = null;
    },
    [profile.fulfilled]: (state, action) => {
      state.profile = action.payload.data.results[0];
    },
    [profile.rejected]: (state, action) => {
      state.profile = null;
    },
  },
});


export const getAuthenticated = (state) => state.auth.isAuthenticated 
export const getProfile = (state) => state.auth.profile
export const { logout, save_profile} = authSlice.actions
export default authSlice.reducer;
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  message: '',
  show: false,
};

const messageSlice = createSlice({
  name: "message",
  initialState,
  reducers: {
    setMessage: (state, action) => {
      state.message = action.payload
    },
    clearMessage: state => {
      state.message = "" 
    },
    showMessage: (state,action) => {
      state.show = action.payload
    }
  },
});

export const { setMessage, clearMessage, showMessage} = messageSlice.actions
export const getMessageState = (state) => state.message

export default messageSlice.reducer
import axios from "axios";
import authHeader from "./auth-header";

import variables from "./variables";

const API_URL = variables.apiURL;

const getPublicContent = () => {
  return axios.get(API_URL + "all");
};

const getValid = () => {
  return axios.get(API_URL + 'api/valid/', { headers: authHeader() })
};

const getAuthValid = () => {
  return axios.get(API_URL + 'api/adminstatus/', { headers: authHeader() })
};

const saveUserProfile = (data) => {
  return axios.put(API_URL + 'api/profile/' + data.username + "/", data, { headers: authHeader() }).then((response) => {
    return response.data;
  });

}


const getUserBoard = () => {
  return axios.get(API_URL + 'api/profile/', { headers: authHeader() })
};

const getModeratorBoard = () => {
  return axios.get(API_URL + "mod", { headers: authHeader() });
};

const getAdminBoard = () => {
  return axios.get(API_URL + "admin", { headers: authHeader() });
};

const userService = {
  getPublicContent,
  getUserBoard,
  getModeratorBoard,
  getAdminBoard,
  getValid,
  saveUserProfile,
  getAuthValid
};

export default userService
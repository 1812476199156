import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";


import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import Stack from 'react-bootstrap/Stack';

import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';

import { getProfile, save_profile, save_api_profile } from "../slices/auth";



const Account = () => {
  const [validated, setValidated] = useState(false);

  const dispatch = useDispatch();
  const profile = useSelector(getProfile)



  

  useEffect(() => {

  }, []);





  const handleFormChange = (event) => {
    let data = Object.assign({}, profile);
    const name = event.target.name
    if (name.includes(".")){
        const name_s = name.split(".");
        let userdata = Object.assign({}, data[name_s[0]]);
        userdata[name_s[1]] = event.target.value;
        data[name_s[0]] = userdata
    }else{
        data[event.target.name] = event.target.value;
    }


    //setInputFields(data);
    dispatch(save_profile(data))
}

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    if (form.checkValidity() === false) {
      event.preventDefault();
      //event.stopPropagation();
    }else{
        setValidated(true);
        dispatch(save_api_profile(profile))
    }
    
    
    
  };


  {
    return (
        <Container>
            {
            profile ?
            <Stack gap={3}>
                <Row>
                    <h1>Account</h1>    

                </Row>
                <Card>
                    <Card.Body>
                    
                    <Form noValidate validated={validated} onSubmit={handleSubmit}>
                    <Stack gap={3}>
                    <Row className="mb-3">
                    <Form.Group as={Col} md="4" controlId="validationCustom01">
                        <Form.Label>First name</Form.Label>
                        <Form.Control
                        required
                        name="first_name"
                        type="text"
                        placeholder="First name"
                        defaultValue="Mark"
                        value={profile.first_name}
                        onChange={handleFormChange}
                        />
                        
                    </Form.Group>
                    <Form.Group as={Col} md="4" controlId="validationCustom02">
                        <Form.Label>Last name</Form.Label>
                        <Form.Control
                        required
                        name="last_name"
                        type="text"
                        placeholder="Last name"
                        defaultValue="Otto"
                        value={profile.last_name}
                        onChange={handleFormChange}
                        />
                        
                    </Form.Group>
                    <Form.Group as={Col} md="4" controlId="validationCustomUsername">
                        <Form.Label>Company</Form.Label>
                        <InputGroup hasValidation>
                        <Form.Control
                            type="text"
                            name="userprofile.company"
                            placeholder="Company"
                            value={profile.userprofile.company}
                            onChange={handleFormChange}
                            aria-describedby="inputGroupPrepend"
                            required
                        />
                        <Form.Control.Feedback type="invalid">
                            Please enter a company.
                        </Form.Control.Feedback>
                        </InputGroup>
                    </Form.Group>
                    </Row>
                    <Row className="mb-12">
                        <Form.Group as={Col} md="12" controlId="validationCustom03">
                            <Form.Label>Street address</Form.Label>
                            <Form.Control 
                                type="text" 
                                name="userprofile.address"
                                placeholder="Street address" 
                                onChange={handleFormChange}
                                value={profile.userprofile.address}
                                required />
                            <Form.Control.Feedback type="invalid">
                            Please provide an Street address
                            </Form.Control.Feedback>
                        </Form.Group>

                    </Row>
                    <Row className="mb-3">
                        <Form.Group as={Col} md="6" controlId="validationCustom03">
                            <Form.Label>City</Form.Label>
                            <Form.Control 
                                type="text" 
                                name="userprofile.city"
                                placeholder="City" 
                                onChange={handleFormChange}
                                value={profile.userprofile.city}
                                required />
                            <Form.Control.Feedback type="invalid">
                            Please provide a valid city.
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} md="3" controlId="validationCustom04">
                            <Form.Label>State</Form.Label>
                            <Form.Control 
                                type="text" 
                                name="userprofile.state"
                                placeholder="State"
                                onChange={handleFormChange}
                                value={profile.userprofile.state} 
                                required />
                            <Form.Control.Feedback type="invalid">
                            Please provide a valid state.
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} md="3" controlId="validationCustom05">
                            <Form.Label>Postcode</Form.Label>
                            <Form.Control 
                                type="text" 
                                name="userprofile.postcode"
                                placeholder="Postcode"
                                onChange={handleFormChange}
                                value={profile.userprofile.postcode}  
                                required />
                            <Form.Control.Feedback type="invalid">
                            Please provide a Postcode.
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Row>
                    <Row className="mb-12">
                        <Form.Group as={Col} md="12" controlId="validationCustom03">
                            <Form.Label>Phone</Form.Label>
                            <Form.Control 
                                type="text" 
                                name="userprofile.telephone"
                                placeholder="Phone"
                                onChange={handleFormChange}
                                value={profile.userprofile.telephone}
                                required />
                            <Form.Control.Feedback type="invalid">
                            Please provide a telephone number
                            </Form.Control.Feedback>
                        </Form.Group>

                    </Row>
                    
                    <Button type="submit">Save</Button>
                    </Stack>
                </Form>  
                </Card.Body> 
            </Card>  
        </Stack>
         : null
        }
      </Container>
    );
  }

};

export default Account;
import React, { useState } from 'react';
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';

import { useDispatch, useSelector, shallowEqual } from "react-redux";

import {getMessageState,showMessage} from "../slices/message"

function MessageToast() {
  const [position, setPosition] = useState('top-end');
  
  const dispatch = useDispatch();

  const message_state = useSelector(getMessageState)


  return (
    <ToastContainer position={position} className="position-fixed">
      <Toast onClose={() => dispatch(showMessage(false))} show={message_state.show} delay={2000} autohide>
        <Toast.Header>
          <strong className="me-auto">Notification</strong>

        </Toast.Header>
        <Toast.Body>{ message_state.message}</Toast.Body>
      </Toast>
     
    </ToastContainer>
  );
}

export default MessageToast;
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useNavigate } from "react-router-dom";
import { logout } from "../slices/auth";


const AccountNotVerified = () => {
  const [show, setShow] = useState(true);


  const handleShow = () => setShow(true);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    
  }, []);

  const handleClose = () => {
      setShow(false);
      dispatch(logout())
      navigate("/signin");
  }
 
  return (
    <>


      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Message from ACRA Awards Team</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Thank you, for logging into the ACRA Awards ticket portal. Your current login details are currently have not been authorised to use the portal. Once authorised you should receive an email to login.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}


export default AccountNotVerified;
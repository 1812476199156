import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { logout, getAuthenticated } from "../slices/auth";
import { useNavigate } from "react-router-dom";
import { setLoading, getLoading} from "../slices/loading";
import Spinner from 'react-bootstrap/Spinner';

import { Outlet, Link } from "react-router-dom";
import Image from 'react-bootstrap/Image';

function CollapsibleExample() {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isAuthenticated = useSelector(getAuthenticated)
  const isloading = useSelector(getLoading)

  useEffect(() => {
    if (!isAuthenticated){
      navigate('/signin')
    }
  }, [isAuthenticated]);

  return (
    <Navbar className="navbaracra" collapseOnSelect expand="lg"  variant="dark" style={{backgroundColor:"#0a2240"}} >
      <Container>
        <Navbar.Brand href="/">
         <Image src="static/images/ACRA_100Years_Lockup.png" height="80px"  />
          { isloading ? 
            <Spinner
                as="span"
                animation="grow"
                size="sm"
                aria-hidden="true"
            />: null
          }
        
        
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link><Link to={``}>Ticketing</Link></Nav.Link>
            <Nav.Link><Link to={`order`}>Orders</Link></Nav.Link>
            <Nav.Link><Link to={`import`}>Import Excel</Link></Nav.Link>
            
          </Nav>
          <Nav>
            <Nav.Link><Link to={`account`}>My Account</Link></Nav.Link>
            <Nav.Link onClick={()=>dispatch(logout())}>
               Logout
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default CollapsibleExample;
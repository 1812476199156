import { configureStore } from '@reduxjs/toolkit'
import authReducer from "./slices/auth";
import messageReducer from "./slices/message";
import ticketReducer from "./slices/ticket";
import loadingReducer from "./slices/loading";

const reducer = {
  auth: authReducer,
  message: messageReducer,
  ticket: ticketReducer,
  loading: loadingReducer,
}

export default configureStore({
  reducer: reducer,
})

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import TicketForm from './TicketForm'
import Stack from 'react-bootstrap/Stack';
import Button from 'react-bootstrap/Button';
import { prefill_table} from "../slices/ticket";

import { ButtonCellTemplate } from "./CellTemplates/ButtonCellTemplate";

import { ReactGrid, CellChange, Row, Column, Id, MenuOption, SelectionMode } from "@silevis/reactgrid";
import "@silevis/reactgrid/styles.css";

import { update_seating,update_table, remove_seat, delete_ticket } from "../slices/ticket";


const TableForm = (props) => {
  const [isDropdownOpened, setIsDropdownOpened] = React.useState([
    false,false,false,false,false,false,false,false,false,false
  ]);

  const [seating, setSeating] = React.useState()
  
  const [successful, setSuccessful] = useState(false);

  const dispatch = useDispatch();

  const {table} = props
  useEffect(() => {
    let data = [...table["seats"]]
    setSeating(data)
  }, []);



  const handleSave = props.handleSave

  const clickSave = (e) =>{
    e.stopPropagation(); 
    props.handleSave()

  }

  const clickPrefill = (e) =>{
        e.stopPropagation();  
        prefill_seats()
        
        //dispatch(prefill_table(user_table))
        
        
  }

  const prefill_seats = () => {
      var current_seats = [...seating]
      var new_seats = new Array()
      for (let k=0; k< 10; k++){
        var current_seat = current_seats[k]

        if (current_seat["firstname"] == "" && current_seat["lastname"] == "" && current_seat["email"] == "" ){
          new_seats.push({ user_table:table["user_table"], table: table["id"], seat_number: k,firstname: 'Ticket', lastname: 'Ticket', email: 'ticket@acras.au', dietary:'none', comments:'', finalist: false, paid: false, reserved:false, after_party:false })
        }else{
          new_seats.push(current_seat)
          
        }

      }
      setSeating(new_seats)
      
      dispatch(update_seating(new_seats))
      //dispatch(prefill_table(new_seats))
  }
  /*
  { user_table:table["user_table"], table: table["id"], seat_number: k,firstname: 'Reserved', lastname: 'Reserved', email: 'reserved@acras.au', dietary:'none', comments:'', finalist: false, paid: false }
  */


  const getColumns = () => [
    { columnId: "reserved", width: 50 },
    { columnId: "firstname", width: 125 },
    { columnId: "lastname", width: 150 },
    { columnId: "email", width: 250 },
    { columnId: "dietary", width: 200 },
    { columnId: "comments", width: 200 },
    { columnId: "after_party", width: 80 },
    { columnId: "finalist", width: 60 },
    { columnId: "paid", width: 50 },
    { columnId: "remove", width: 80 }
  ];

  const simpleHandleContextMenu = (
    selectedRowIds,
    selectedColIds,
    selectionMode,
    menuOptions
  ) => {
    return menuOptions
  }

  
  const headerRow = {
    rowId: "header",
    cells: [
      { type: "header", text: "Reserve" },
      { type: "header", text: "First Name" },
      { type: "header", text: "Last Name" },
      { type: "header", text: "Email" },
      { type: "header", text: "Dietary" },
      { type: "header", text: "Comments" },
      { type: "header", text: "After Party" },
      { type: "header", text: "Finalist" },
      { type: "header", text: "Paid" },
      { type: "header", text: "" },
    ]
  };


  const dietary = [{label:"None", value:"none" },
                    {label:"Gluten Free", value: "gluten_free"},
                    {label:"Halal", value: "halal"},
                    {label:"Kosher", value: "kosher"},
                    {label:"Lactose Intolerence", value: "lactose_intolerence"},
                    {label:"Nut Allergy", value: "nut_allergy"},
                    {label:"Pescatarian", value: "pescatarian"},
                    {label:"Vegan", value: "vegan"},
                    {label:"Vegetarian", value: "vegetarian"},
                    {label:"Other (Comments)", value: "other"},
                
                ]

  const email_valid = ( email ) => {

    // don't remember from where i copied this code, but this works.
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if ( re.test(email) ) {
        return true
    }
    else {
        return false
    }

}
              

 const requiredInputs = (seat) => ((seat.firstname.length == 0 && seat.lastname.length == 0 && seat.email.length == 0)| seat.paid)
  const getRows = () => [
    headerRow,
    ...seating.map((seat, idx) => ({
      rowId: idx,
      cells: [
        { type: "checkbox", checked: (seat.reserved ? seat.reserved:false ) },
        { type: "text", text: seat.firstname },
        { type: "text", text: seat.lastname },
        { type: "email", text: seat.email, validator: (email) => email_valid(email)},
        //{ type: "dropdown", text:seat.dietary, selectedValue: seat.dietary, values: dietary, isOpen: isDropdownOpened[idx] },
        { type: "text", text:seat.dietary},
        { type: "text", text: seat.comments },
        { type: "checkbox", checked: seat.after_party },
        { type: "checkbox", checked: seat.finalist },
        { type: "checkbox", checked: seat.paid },
        (requiredInputs(seat) ? { type: "button", text: "", seat: idx, seat_obj:seat}:{ type: "button", text: "Remove", seat: idx, seat_obj:seat})



      ]

    }))
  ];
  /*
   let data = Object.assign({}, seat);
   data[event.target.name] = event.target.value;
   setInputFields(data);
   dispatch(update_table(data))
   const form = event.currentTarget;

  */
   const applySeatingChanges = (changes, prevSeating) => {

    changes.forEach(change => {
      if (change.newCell.type === "button"){
        
        let prevSeat = change.previousCell
        //console.log(change)
        if (prevSeat){
            if (change.newCell.seat_obj.firstname != "" ) {
              let data = handleRemove(change.newCell.seat_obj)
              const seatIndex = change.rowId
              prevSeating[seatIndex] = data
            }
          }
      }
      if (change.newCell.type === "text" | change.newCell.type === "email") {
        const seatIndex = change.rowId
        const fieldName = change.columnId
        let data = Object.assign({}, prevSeating[seatIndex])
        data[fieldName] = change.newCell.text
        prevSeating[seatIndex] = data
        
      }
      if( change.newCell.type === "checkbox"){
        const seatIndex = change.rowId
        const fieldName = change.columnId
        if (fieldName != "paid" && fieldName != "reserved"){
              let data = Object.assign({}, prevSeating[seatIndex])
              if (prevSeating[seatIndex]["paid"] == false && prevSeating[seatIndex]["reserved"] == false){
                data[fieldName]= change.newCell.checked
                prevSeating[seatIndex] = data
              }
        }
      }
      if (change.newCell && change.newCell.type === "dropdown") {
        const seatIndex = change.rowId
        const fieldName = change.columnId
        var isDropdownOpenedCopy = [...isDropdownOpened]; 
        
        if (change.newCell.isOpen !== isDropdownOpened[seatIndex]) {
          isDropdownOpenedCopy[seatIndex] =  change.newCell.isOpen
          setIsDropdownOpened(isDropdownOpenedCopy);
          
        }
        
        let prevSeat = change.previousCell
        //console.log(change)
        if (prevSeat){
            if (change.newCell.selectedValue != prevSeat.selectedValue ) {
                let data = Object.assign({}, prevSeating[seatIndex])
                data[fieldName]= change.newCell.text
                prevSeating[seatIndex] = data
            }
        }

          
      }
    })
    return [...prevSeating]
  } 
   
  const handleChanges = changes => {
    setSeating(prevSeating => applySeatingChanges(changes, prevSeating))
    dispatch(update_seating(seating))
  }

  /*
  const handleChanges = (changes,prevSeat) => {
    function updateData(data) {
       dispatch(update_table(data))
    }
    changes.forEach(change => {
      console.log(change)
      if (change.newCell.type === "button"){
        handleRemove(change.newCell.seat_obj)
      }
      if (change.newCell.type === "text" | change.newCell.type === "email") {
        const seatIndex = change.rowId
        const fieldName = change.columnId
        prevSeat = table["seats"][seatIndex]
        var data = { user_table:prevSeat["user_table"] , table: prevSeat["table"], seat_number: prevSeat["seat_number"],firstname: prevSeat["firstname"], lastname: prevSeat["lastname"], email:prevSeat["email"], dietary:prevSeat["dietary"], comments:prevSeat["comments"], finalist: prevSeat["finalist"], paid: prevSeat["paid"],reserved:prevSeat["reserved"] }
        prevSeat[fieldName]= change.newCell.text
        updateData(data)
        
      }
      if( change.newCell.type === "checkbox"){
        const seatIndex = change.rowId
        const fieldName = change.columnId
        if (fieldName != "paid" && fieldName != "reserved"){
            prevSeat = table["seats"][seatIndex]
            var data = { user_table:prevSeat["user_table"] , table: prevSeat["table"], seat_number: prevSeat["seat_number"],firstname: prevSeat["firstname"], lastname: prevSeat["lastname"], email:prevSeat["email"], dietary:prevSeat["dietary"], comments:prevSeat["comments"], finalist: prevSeat["finalist"], paid: prevSeat["paid"], reserved:prevSeat["reserved"]}
            data[fieldName]= change.newCell.checked
            updateData(data)
        }
      }
      if (change.newCell && change.newCell.type === "dropdown") {
        const seatIndex = change.rowId
        const fieldName = change.columnId
        var isDropdownOpenedCopy = [...isDropdownOpened]; 
        
        if (change.newCell.isOpen !== isDropdownOpened[seatIndex]) {
          isDropdownOpenedCopy[seatIndex] =  change.newCell.isOpen
          setIsDropdownOpened(isDropdownOpenedCopy);
          
        }
        
        prevSeat = change.previousCell
        //console.log(change)
        if (prevSeat){
            if (change.newCell.selectedValue != prevSeat.selectedValue ) {
                var seat = table["seats"][seatIndex]
                var data = { user_table:seat["user_table"] , table: seat["table"], seat_number: seat["seat_number"],firstname: seat["firstname"], lastname: seat["lastname"], email:seat["email"], dietary:seat["dietary"], comments:seat["comments"], finalist: seat["finalist"], paid: seat["paid"], reserved: seat["reserved"]}
                //console.log(change.newCell)
                data[fieldName]= change.newCell.text
                updateData(data)
            }
        }

          
      }
    })
  }
  */
 

  const [inputFields, setInputFields] = useState(
    { firstname: '', lastname: '', email: '', dietary:'none', comment:'', finalist: false, paid:false,reserved:false, after_party:false }
  )
  
  const handleRemove = (seat) =>{
    
    let data = Object.assign({}, seat);
    data.firstname = ''
    data.lastname = ''
    data.email = ''
    data.dietary = 'none'
    data.comments = ''
    data.reserved = false
    data.finalist = false
    data.after_party = false
    //setInputFields(data);
    
    
    //dispatch(update_seating(new_seating))
    dispatch(delete_ticket(seat))
    
    //dispatch(remove_seat(data))
    return data
  }

  return (

            <Accordion key={props.id} defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                    <Accordion.Header>
                        <Stack direction="horizontal" gap={3}>
                            <div>Table {props.table["user_table"] + 1}   <Button variant="success" onClick={(e)=>clickSave(e)} >Save</Button>{' '}</div>
                            
                        </Stack>
                        </Accordion.Header>
                    <Accordion.Body>
                          
                            {
                                <div className="ticketslist">
                                  <div className="ticketbox">
                                    { (seating &&

                                      <ReactGrid rows={getRows()} columns={getColumns()} 
                                      
                                      enableRangeSelection
                                      enableFillHandle
                                      onCellsChanged={handleChanges}
                                      onContextMenu={simpleHandleContextMenu}
                                      customCellTemplates={{button: new ButtonCellTemplate()}}
                                    

                                      />)
                                    }
                                    </div>
                                 </div>
                            }
                  
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>

  );
};

export default TableForm;
import { createAsyncThunk,createReducer, createSlice } from '@reduxjs/toolkit'
import ticketService from "../services/ticket.service";

import { setLoading} from "./loading";


const initialState = { tables: [], loading: true, ticket_cutoff: null, acra: null, ticket_total:0, ticket_count: 0 }

export const get_api_acra = createAsyncThunk(
  "ticket/getacra",
  async (payload, thunkAPI) => {
    thunkAPI.dispatch(setLoading(true));
    try {
      const response = await ticketService.getAcra();
      //thunkAPI.dispatch(setMessage(response.data.message));
      thunkAPI.dispatch(setLoading(false));
      console.log(response.results)
      return response.results[0];
    } catch (error) {
      console.log(error)
      thunkAPI.dispatch(setLoading(false));
      return thunkAPI.rejectWithValue();
    }
  }
); 


export const get_api_tables = createAsyncThunk(
  "ticket/gettables",
  async (payload, thunkAPI) => {
    thunkAPI.dispatch(setLoading(true));
    try {
      const response = await ticketService.getTables();
      //thunkAPI.dispatch(setMessage(response.data.message));
      thunkAPI.dispatch(setLoading(false));
      console.log(response.results)
      return response.results;
    } catch (error) {
      console.log(error)
      thunkAPI.dispatch(setLoading(false));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const clean_tables = createAsyncThunk(
  "ticket/clean_tables",
  async (data, thunkAPI) => {
    try {
      
      const response = await ticketService.cleanTables()
      console.log(response)
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data)
      console.log(error)
      return thunkAPI.rejectWithValue(message);
    }
  }
);


export const confirm_import_excel = createAsyncThunk(
  "ticket/confirm_import_excel",
  async (data, thunkAPI) => {
    try {
      
      const response = await ticketService.confirmExcelImport(data)
      console.log(response)
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data)
      console.log(error)
      return thunkAPI.rejectWithValue(message);
    }
  }
);
 


export const save_import_excel = createAsyncThunk(
  "ticket/import_excel",
  async (data, thunkAPI) => {
    try {
      
      const response = await ticketService.saveExcel(data)
      console.log(response)
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data)
      console.log(error)
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const save_api_tables = createAsyncThunk(
  "ticket/save",
  async (tables, thunkAPI) => {
    try {
      var data_output = Array()
      for (let i = 0; i < tables.length; i++) {
        const current_table = tables[i]
        var current_seats = [ ...current_table["seats"]] 
        current_seats = current_seats.filter(value => value["firstname"].length !== 0);
        data_output.push({'user_table':current_table['user_table'],'seats':current_seats})
        
      } 
      const response = await ticketService.saveTables(data_output)

      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data)
      console.log(error)
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const delete_ticket = createAsyncThunk(
  "ticket/removeticket",
  async (seat, thunkAPI) => {
    try {
      if (seat){
        const response = await ticketService.removeTicket(seat.id)
        
        return response.data;
      }
      return null
    } catch (error) {
      const message =
        (error.response &&
          error.response.data)
      console.log(error)
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const save_api_order = createAsyncThunk(
  "ticket/saveorder",
  async (data, thunkAPI) => {
    try {
      const response = await ticketService.createOrder()

      return response.message;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data)
      console.log(error)
      return thunkAPI.rejectWithValue(message);
    }
  }
);

function empty_seats(tableno){
  var seats = new Array();
  var n = 10;
  for (let i = 0; i < n; i++) {
    seats.push({ 'user_table':tableno, table: null, seat_number: i,firstname: '', lastname: '', email: '', dietary:'none', comments:'', finalist: false, paid: false, reserved:false, after_party:false })
  }

  return seats
  
}

function prefill_seats(table_no, tables){
  var new_tables = new Array()
  for (let i = 0; i < tables.length; i++){
    var table = tables[i]
    if (table_no === table["user_table"]){
        var current_seats = table["seats"]
        var new_seats = new Array()
        for (let k=0; k< 10; k++){
          var current_seat = current_seats[k]

          if (current_seat["firstname"] == "" && current_seat["lastname"] == "" && current_seat["email"] == "" ){
            new_seats.push({ user_table:table["user_table"], table: table["id"], seat_number: k,firstname: 'Ticket', lastname: 'Ticket', email: 'ticket@acras.au', dietary:'none', comments:'', finalist: false, paid: false, reserved:false, after_party:false  })
          }else{
            new_seats.push(current_seat)
            
          }

        }
        table["seats"]=new_seats
    }
    new_tables.push(table)
  }
  return new_tables

}

function fill_seats(tables, cut_off){
  var new_tables = new Array()
  for (let i = 0; i < tables.length; i++){
    var table = tables[i]
    var current_seats = table["seats"]
    var len_seats = current_seats.length
    var left = 10 - len_seats
    var seats = new Array()
    for (let k=0; k< 10; k++){
      var current_seat = current_seats.filter(value => value["seat_number"] === k);
      if (current_seat.length > 0){
        current_seat = current_seat[0]
        current_seat["user_table"] = table["user_table"]
        seats.push(current_seat)
      }else{
        if ( new Date() < cut_off){
          seats.push({ user_table:table["user_table"], table: table["id"], seat_number: k,firstname: '', lastname: '', email: '', dietary:'none', comments:'', finalist: false, paid: false, reserved:false, after_party:false })
        }
      }
      
    }
    if (len_seats == 11){
      seats.push(current_seats[10])
      //current_seat = current_seats[10]
      //seats.push(current_seat)
    }


    /*
    for (let k=0; k< len_seats;k++){
      seats[k]["user_table"] = table["user_table"]
    }
    if (left > 0){
      for (let y=len_seats ; y < 10;y++ ){
        seats.push({ user_table:table["user_table"], table: table["id"], seat_number: y,firstname: '', lastname: '', email: '', cuisine:'', comments:'', finalist: false, paid: false })
      }
    }*/
    table["seats"]=seats
    new_tables.push(table)
  }
  return new_tables
}

const ticketSlice = createSlice({
    name: 'ticket',
    initialState,
    reducers: {
      set_acra:(state,action)=>{
        state.acra = action.payload
      },
      set_ticket_cutoff:(state,action)=>{
        state.ticket_cutoff = action.payload
      },
      set_ticket_total:(state,action)=>{
        state.ticket_total = action.payload
      },
      set_ticket_count:(state,action)=>{
        state.ticket_count = action.payload
      },
      set_table:(state,action)=>{
        //console.log(action.payload)

        state.tables = fill_seats(action.payload, new Date(state.ticket_cutoff))

      },
      prefill_table:(state,action)=>{
        state.tables = prefill_seats(action.payload, state.tables)
      },
      add_table:(state) => {
        var tableno = 0
        if (state.tables.length == 0){
            tableno = 0
        }else{
          tableno = state.tables[state.tables.length - 1]['user_table'] + 1
        }
        state.tables = [...state.tables, {'user_table':tableno,'seats':empty_seats(tableno)}];

      },
      update_table:(state, action) =>{
        const seat = action.payload
        state.tables[seat.user_table].seats[seat.seat_number] = action.payload
      },
      update_seating:(state,action)=>{
        const seating = action.payload
        //console.log("update_seating",seating)
        state.tables[seating[0].user_table].seats = action.payload
      },
      remove_seat:(state,action) =>{
        const seat = action.payload
  
        //state.tables[seat.user_table].seats.splice(seat.seat_number,1)
        console.log(seat)
        seat.firstname = ''
        seat.lastname = ''
        seat.email = ''
        seat.dietary = 'none'
        seat.comments = ''
        seat.reserved = false
        state.tables[seat.user_table].seats[seat.seat_number] = seat

      }

    },
  })



export const getTables = (state) => state.ticket.tables
export const getCutOff = (state) => state.ticket.ticket_cutoff
export const getTicketTotal = (state) => state.ticket.ticket_total
export const getTicketCount = (state) => state.ticket.ticket_count
export const getACRAs = (state) => state.ticket.acra
export const { update_seating, add_table, update_table,set_table, remove_seat, set_ticket_cutoff, set_acra, prefill_table, set_ticket_total, set_ticket_count   } = ticketSlice.actions
export default ticketSlice.reducer
import axios from "axios";
import authHeader from "./auth-header";

import variables from "./variables";
const API_URL = variables.apiURL;


const getAcraViewonly = () => {
  return axios.get(API_URL + 'api/map/').then((response) => {
    return response.data;
  });
};

const getTicketViewonly = () => {
  return axios.get(API_URL + 'api/ticket/search/').then((response) => {
    return response.data;
  });
};

const getAcra = () => {
  return axios.get(API_URL + 'api/acra/', { headers: authHeader() }).then((response) => {
    return response.data;
  });
};


const getOrderStatus = (id) => {
  return axios.get(API_URL + 'api/order/process/?session_id=' + id, { headers: authHeader() }).then((response) => {
    return response.data;
  });
};

const getOrder = (id) => {
  return axios.get(API_URL + 'api/orders/' + id+ '/', { headers: authHeader() }).then((response) => {
    return response.data;
  });
};

const putOrder = (id,data) => {
  return axios.put(API_URL + 'api/orders/all/' + id+ '/',data, { headers: authHeader() }).then((response) => {
    return response.data;
  });
};


const createOrder = () => {
  return axios.get(API_URL + 'api/createorder/', { headers: authHeader() }).then((response) => {
    return response.data;
  });
};

const saveTables = (data) => {
  return axios.post(API_URL + 'api/tables/', data, { headers: authHeader() }).then((response) => {
    return response.data;
  });

}

const removeTicket = (id) => {
  return axios.delete(API_URL + 'api/order/tickets/?id='+ id, { headers: authHeader() }).then((response) => {
    return response.data;
  });

}

const getTables = () => {
  console.log("Gettings tables")
  return axios.get(API_URL + 'api/tables/', { headers: authHeader() }).then((response) => {
    return response.data;
  });
};

const getAdminTables = () => {
  console.log("Gettings tables")
  return axios.get(API_URL + 'api/admin/tables/', { headers: authHeader() }).then((response) => {
    return response.data;
  });
};

const getOrders = () => {
  return axios.get(API_URL + 'api/orders/', { headers: authHeader() }).then((response) => {
    return response.data.results;
  });
};

const saveExcel = (data) =>{
  	let form_data = new FormData()
    if (data.document)
      form_data.append("document",data.document);
    return axios.post(API_URL + 'api/table/uploads/',form_data,{ headers: authHeader(),  "Content-Type": "multipart/form-data" }).then((response) => {
      return response.data;
    });
}

const confirmExcelImport = (data) => {
  return axios.post(API_URL + 'api/table/confirm/', data, { headers: authHeader() }).then((response) => {
    return response.data;
  });
}

const cleanTables = () => {
  return axios.post(API_URL + 'api/table/clean/', {"success": true}, { headers: authHeader() }).then((response) => {
    return response.data;
  });
}

const ticketService = {
  getTables,
  saveTables,
  createOrder,
  getOrders,
  getOrder,
  getOrderStatus,
  removeTicket,
  saveExcel,
  confirmExcelImport,
  cleanTables,
  getAcra,
  getAcraViewonly,
  getTicketViewonly,
  getAdminTables,
  putOrder
};

export default ticketService
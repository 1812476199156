import axios from "axios";
import authHeader from "./auth-header";

import variables from "./variables";

const API_URL = variables.apiURL;

const register = (data) => {
    return axios.post(API_URL + "rest-auth/registration/", data);
  };
  
  const login = (email, password) => {
    return axios
      .post(API_URL + "rest-auth/login/", {
        email,
        password,
      })
      .then((response) => {
        if (response.data.key) {
          localStorage.setItem("acra-ticket-user", response.data.key);
        }
  
        return response.data;
      });
  };
  
  const logout = () => {
    localStorage.removeItem("acra-ticket-user");
    return axios.post(API_URL + 'rest-auth/logout/', {}, { headers: authHeader() }).then((response) => {
      return response.data;
    });
    
  };
  
  const authService = {
    register,
    login,
    logout,
  };
  
  export default authService;
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import ticketService from "../services/ticket.service";
import Image from 'react-bootstrap/Image';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Select from 'react-select'
import { MapInteractionCSS } from 'react-map-interaction';
import Stack from 'react-bootstrap/Stack';
import Navbar from 'react-bootstrap/Navbar';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';

const TicketSearch = () => {
  const [successful, setSuccessful] = useState(false);
  const [tickets, setTickets] = useState();
  const [value, setValue] = useState();
  const [acra, setAcra] = useState();
  const dispatch = useDispatch();

  const [state, setState] = useState({
    value: {
      scale: 0.75,
      translation: { x: 0, y: 0 }
  }})

  useEffect(() => {
    async function fetchData() {
      try{
          let response = await ticketService.getAcraViewonly()
          let data = response.results
          setAcra(data[0])
          response = await ticketService.getTicketViewonly()
          data = response.results
          setTickets(data)

      }catch (error) {
        console.log(error)
      }
    }
    fetchData();
  }, []);

  const goToTop = () => {
    window.scrollTo(0,0);
};
  
  const initialValues = {
    name: "",
    email: "",
    password: "",
    company: "",
  };

  return (
    <Container>
      <Button onClick={()=>goToTop()} style={{position:"fixed", bottom:"0px", 
          right: "0px", zIndex: "9999"
            }}> Back to Search </Button>
      <Stack gap={3}> 
      <Navbar className="navbaracra" collapseOnSelect expand="lg"  variant="dark" style={{backgroundColor:"#0a2240"}} >
      <Container>
        <Navbar.Brand href="/">
          <Image src="https://tickets.acras.au/static/images/ACRA_100Years_Lockup.png" height="80px" />

        </Navbar.Brand>
      </Container>
      </Navbar>
         
          <Row>
          {
            tickets &&
              <Select 
                options={tickets}
                placeholder={"Type first or last name"}
                getOptionLabel={(ticket)=>ticket.full_name}
                getOptionValue={(ticket)=>ticket.real_table}
                onChange={(value)=>setValue(value)}
                isSearchable 
              />
            
          }
          </Row>
          { value &&
            <Row>
              <Stack gap={3}> 
                  <span style={{fontSize:"34px"}}><strong>{value.full_name}</strong> your table number is <strong>{value.real_table}</strong></span>
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th>Name</th>   
                        <th>Table Number</th>
                      </tr>
                    </thead>
                  <tbody>
                    {
                      tickets.filter(ticket => ticket.real_table === value.real_table).map(ticket => (
                        <tr>
                          <td>{ticket.firstname} <strong>{ticket.lastname}</strong> </td>
                          <td>{ticket.real_table}</td>
                        </tr>


                      )
                        
                        
                        )
                    }
                  </tbody>
                  </Table>
              </Stack>
            </Row>
          }
          <Row>
            <h3> Seating Map</h3>
            <Stack direction="horizontal" gap={3}>
              <span>
                 Mouse and Touch enabled.
              </span>
              <span>
                Pinch zoom in and out.
              </span>
              <span>
                 Map is scrollable
              </span>
            </Stack>
          </Row>
        <Row  className="justify-content-md-center">
        {

        acra &&
            <MapInteractionCSS
              value={state.value}
              onChange={(value) => setState({ value })}>
              <img src={acra.site_map} />
            </MapInteractionCSS>
        }
        </Row>
        
      </Stack>
      <Row>
          

      </Row>
    </Container>
  );
};

export default TicketSearch;
import logo from './logo.svg';
import { Routes, Route, BrowserRouter, useParams } from 'react-router-dom'
import './App.css';
import Main from './components/Main'
import UserRegistration from './components/UserRegistration'
import UserSignIn from './components/UserSignIn'

const Whoops404 = () => (
	<div className="">
		Page does not exist!
		
    </div>
);

function App() {
  return (
   <></>
  );
}

export default App;

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useSearchParams } from "react-router-dom";

import { getProfile,profile } from "../slices/auth";
import ticketService from "../services/ticket.service";


import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Stack from 'react-bootstrap/Stack';
import Card from 'react-bootstrap/Card';
import Badge from 'react-bootstrap/Badge';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import { get_api_tables,set_table } from "../slices/ticket";

const Order_Complete = () => {
  let [searchParams, setSearchParams] = useSearchParams();
  const [successful, setSuccessful] = useState(null);
  const [show, setShow] = useState(false);
  const [orderid, setOrderid] = useState(null);
  let [sessionid, setSessionid] = useState(searchParams.get("session_id"))

  const dispatch = useDispatch();

  useEffect(() => {
    async function fetchData() {
        try{
            const response = await ticketService.getOrderStatus(sessionid)
            setSuccessful(response["success"])
            setShow(true)
            setOrderid(response["order"])
            dispatch(get_api_tables()).unwrap()
            .then((data) => {
                console.log(data)
                dispatch(set_table(data))
                
            })
            .catch((error) => {

                console.log(error)

            });
        }catch (error) {
            //setIsError(true)
        }
      }
      fetchData();
      


}, []);

  const order_link = "/invoice?order="+ orderid
  const success_msg = <><p> Thank you, for your payment, your payment has been received for order {orderid} </p><p> Your invoice can be found <a target="_blank" href={order_link}>here</a></p><p> If you require more information. Please contact<a href="mailto:events@commercialradio.com.au"> events@commercialradio.com.au</a> </p></>
  const fail_msg = <><p> An error has occured while trying to pay for your order {orderid}, please try again</p>  <p> or</p>  <p> contact <a href="mailto:events@commercialradio.com.au"> events@commercialradio.com.au</a></p>  </>


  return (
    <Container>
        <Stack gap={3}>
            <Row>
                <Col>
                    { 
                        show ?
                        <>
                            <h1> ACRA Ticket Payment {successful ? <>Success</> : <>Unsuccessful</>}</h1>
                            <Card>  
                                <Card.Body>
                                    <p>{successful ? success_msg : fail_msg}</p>


                                
                                </Card.Body>
                            </Card>
                        </>: 
                        <>
                            <h1> Getting ACRA Ticket Payment Status </h1>
                            <Card>  
                                <Card.Body>
                                    <p>Please wait.. Updating order and ticket status..</p>


                                
                                </Card.Body>
                            </Card>
                        
                        </>
                    }
                </Col>
            </Row>
        </Stack>
    </Container>
  );
};

export default Order_Complete;
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import NavBar from './NavBar'
import Ticketing from './Ticketing'
import MessageToast from './MessageToast'
import Order from './Order'
import Account from './Account'

import UserService from "../services/user.service";
import { useNavigate } from "react-router-dom";

import { get_api_tables,set_table, set_ticket_cutoff, set_ticket_total, set_ticket_count } from "../slices/ticket";
import { logout, getAuthenticated } from "../slices/auth";
import { profile } from "../slices/auth";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Outlet } from "react-router-dom";


const Main = (props) => {
  const [successful, setSuccessful] = useState(false);


  const dispatch = useDispatch();
  const navigate = useNavigate();



  useEffect(() => {
    async function fetchData() {
        try{
            const response = await UserService.getValid()
            let data = response.data
            if (!data["valid"]){
              
              navigate("/account/unverified");
            }
            dispatch(profile())
           
            dispatch(set_ticket_cutoff(data["ticket_cutoff"]))
            dispatch(set_ticket_total(data["ticket_total"]))
            dispatch(set_ticket_count(data["ticket_count"]))
            dispatch(get_api_tables()).unwrap()
            .then((data) => {

              dispatch(set_table(data))
              
            })
            .catch((error) => {

              console.log(error)

            });

        }catch (error) {
          await localStorage.removeItem("acra-ticket-user");
          dispatch(logout())
          navigate("/signin");
        }
      }
      fetchData();
      
  }, []);

  
  const initialValues = {
    name: "",
    email: "",
    password: "",
    company: "",
  };



  return (
    <>
      <Container>
            <Row>
              <NavBar />
              <MessageToast />
              <Outlet />
              
            </Row>
      </Container>
    </>
  );
};

export default Main;
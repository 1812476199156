import React from 'react';
import { useTable } from 'react-table';

const DataTable = ({ data }) => {
  // Define the columns for the table
  const columns = React.useMemo(
    () => [
      {
        Header: 'firstname',
        accessor: 'firstname', // Replace 'column1' with the actual key in your data
      },
      {
        Header: 'lastname',
        accessor: 'lastname', // Replace 'column2' with the actual key in your data
      },
      {
        Header: 'email',
        accessor: 'email', // Replace 'column2' with the actual key in your data
      },
      {
        Header: 'dietary',
        accessor: 'dietary', // Replace 'column2' with the actual key in your data
      },
      {
        Header: 'comments',
        accessor: 'comments', // Replace 'column2' with the actual key in your data
      },
      // Add more columns as needed
    ],
    []
  );

  // Create a table instance
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({ columns, data });

  return (
    <table {...getTableProps()} style={{ border: '1px solid black', width: '100%' }}>
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()} style={{ backgroundColor: 'lightgray' }}>
            {headerGroup.headers.map((column) => (
              <th {...column.getHeaderProps()} style={{ padding: '5px', border: '1px solid black' }}>
                {column.render('Header')}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map((cell) => (
                <td {...cell.getCellProps()} style={{ padding: '5px', border: '1px solid black' }}>
                  {cell.render('Cell')}
                </td>
              ))}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default DataTable;

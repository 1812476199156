import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

import { login, getAuthenticated } from "../slices/auth";
import { setLoading, getLoading} from "../slices/loading";
import { useNavigate } from "react-router-dom";
import Spinner from 'react-bootstrap/Spinner';
import Image from 'react-bootstrap/Image';

const SignIn = () => {
    const [successful, setSuccessful] = useState(false);
    const [servermessage, setServermessage] = useState();
    const [successmsg, setSuccessmsg] = useState();

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const isloading = useSelector(getLoading)
    const isAuthenticated = useSelector(getAuthenticated)

    useEffect(() => {
        if (isAuthenticated){
            navigate('/')
        }
      }, []);

    const initialValues = {
        email: "",
        password: "",
      };
    

    const validationSchema = Yup.object().shape({
        email: Yup.string()
          .email("This is not a valid email.")
          .required("This field is required!"),
        password: Yup.string()
          .required("This field is required!"),
      });

      const handleLogin = (formValue) => {
        const { email, password } = formValue;
    
    
        //email, password1,password2, first_name,last_name,company
        setSuccessful(false);
        dispatch(setLoading(true))
    
        dispatch(login({ email, password}))
          .unwrap()
          .then(() => {
            setSuccessful(true);
            setServermessage("")
            setServermessage({message:"Login Successful"})
            navigate('/')
            dispatch(setLoading(false))
            
          })
          .catch((error) => {
            setSuccessful(false);
            console.log(error)
            setServermessage(error)
            dispatch(setLoading(false))
          });
      };
    

    return(
        <div className="Auth-form-container">
             
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleLogin}
                >
                <Form className="Auth-form">
                    <div className="Auth-form-content">
                        <div style={{textAlign:"center"}}>
                            <Image src="static/images/ACRA_100Years_Lockup.png" fluid />
                        </div>
                        <h3 className="Auth-form-title">Ticket Sign In</h3>
                        <p style={{textAlign:"center"}}>The event is held at the The Star in Sydney on October 12th 2024</p>
                        {servermessage && (
                            Object.keys(servermessage).map((key,i) => (
                                <div className="form-group">
                                
                                    <div
                                        className={successful ? "alert alert-success" : "alert alert-danger"}
                                        role="alert"
                                    >
                                    {servermessage[key]}
                                    </div>
                                
                                </div>
                            ))
                        )}
                        <div className="form-group  mt-3">
                                <label htmlFor="email">Email</label>
                                <Field name="email" type="email" className="form-control" placeholder="Enter email address" />
                                <ErrorMessage
                                    name="email"
                                    component="div"
                                    className="alert alert-danger"
                                />
                        </div>
                        <div className="form-group  mt-3">
                            <label htmlFor="password">Password</label>
                            <Field
                                name="password"
                                type="password"
                                className="form-control"
                                placeholder="Enter password"
                        />
                        <div className="d-grid gap-2 mt-3">
                            <button type="submit" className="btn btn-primary">
                                { isloading ? 
                                    <Spinner
                                        as="span"
                                        animation="grow"
                                        size="sm"
                                        aria-hidden="true"
                                    />: null
                                }
                            Submit
                            </button>
                        </div>
                        
                        <p className="forgot-password text-right mt-2">
                            Need an account? <a href="/register">Register</a>
                        </p>

                        <p> For further enquires, please contact <a href="mailto:events@commercialradio.com.au">events@commercialradio.com.au</a></p>
                        </div>
                    </div>
                </Form>
            </Formik>
        </div>
    )
    

}

export default SignIn;
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';

import { useSearchParams } from "react-router-dom";

import { getProfile,profile } from "../slices/auth";
import ticketService from "../services/ticket.service";

const Invoice = () => {
    let [searchParams, setSearchParams] = useSearchParams();
    let [orderid, setOrderid] = useState(searchParams.get("order"))
    let [order, setOrder] = useState(null)
    let [iserror, setIsError] = useState(false)


  const dispatch = useDispatch();
  const account = useSelector(getProfile)
  
  const ticket_price = 299
  const cc_ticket_price = 304.63

  const after_party = 99
  const after_party_cc = 102.08



  useEffect(() => {
    dispatch(profile())
    async function fetchData() {
        try{
            const response = await ticketService.getOrder(orderid)
            setOrder(response)

        }catch (error) {
            setIsError(true)
        }
      }
      fetchData();
    
  }, []);



  const doPrint = () => {
    window.print();
   
  }


  const doClick = (url,ticket_no, order,after_no) => {
    let new_url = url.replace("<order_id>", order);
    new_url = new_url.replace("<ticket_no>", ticket_no);
    new_url = new_url.replace("<after_no>", after_no);
    // get the data from the api
    //navigate(new_url)
    
    window.location =new_url
   
  }

  

  return (
    <>
    {
        iserror ?
        <div className="h-100 d-flex align-items-center justify-content-center">
            <div >
                ACCESS DENIED
            </div>
        </div>: null 
    }
    <Container>
        {
        order && account ?
        <>
        <Card className="p-4 invoice">
            <Card.Body>
            <Container className="mb-2 mt-3">
                <Row className="d-flex align-items-baseline">
                <Col xl="9">
                    <img width="250" src="https://www.commercialradio.com.au/RA/media/General/Site%20UI/CRA_LOGO_Commercial_Radio_and_Audio_Positive_RGB_Gradient.png"></img>
                    <p style={{ color: "#7e8d9f", fontSize: "20px", marginLeft:"20px" }}>
                       Invoice &gt; &gt; <strong>ID: #{orderid}</strong>
                    </p>
                </Col>
                <Col xl="3" className="float-end">
                    <Button
                    color="light"
                    ripple="dark"
                    className="text-capitalize border-0"
                    onClick={()=> doPrint()}
                    >
                    
                    Print
                    </Button>

                    <hr />
                </Col>
                </Row>
            </Container>
            <Container>
                <Col md="12" className="">
                
                <p className="pt-0"></p>
                </Col>
            </Container>
            <Row>
                <Col>
                <ul className="nostyleli">
                    <li className="text-muted">
                    To: <span style={{ color: "#5d9fc5" }}>{account.first_name} {account.last_name}</span>
                    </li>
                    <li className="text-muted">{account.userprofile.company}</li>
                    <li className="text-muted">{account.userprofile.address}</li>
                    <li className="text-muted">{account.userprofile.city}</li>
                    <li className="text-muted">{account.userprofile.state} {account.userprofile.postcode}</li>
                    <li className="text-muted">{account.userprofile.telephone}</li>
                </ul>
                </Col>
                <Col style={{display: "flex",justifyContent: "flex-end"}}>
                <div>
                <ul className="nostyleli">
                    <li className="text-muted">
                    
                    <span className="ms-1">Commercial Radio & Audio Ltd.</span></li>
                    <li className="text-muted">
            
                    <span className="ms-1">Level 5, 88 Foveaux St</span>
                    </li>
                    <li className="text-muted">
            
                    <span className="ms-1">Surry Hills, NSW, 2010</span>
                    </li>
                  
                </ul> 
                <ul className="nostyleli">
                    <li className="text-muted">
                    
                    <span className="fw-bold ms-1">ID:</span>#{orderid}</li>
                    <li className="text-muted">
            
                    <span className="fw-bold ms-1">Creation Date: </span>{order.created}
                    
                    </li>
                    <li className="text-muted">
            
                    <span className="fw-bold ms-1">Payment Method: </span>{order.payment_type}
                    
                    </li>
                    <li className="text-muted">

                    <span className="fw-bold ms-1">Status:</span>
                    <span className="badge bg-warning text-black fw-bold ms-1">
                        {order.paid ?  " Paid" : "Unpaid"  }
                    </span>
                    </li>
                </ul>
                </div>
                </Col>
            </Row>
            <Row className="my-2 mx-1 justify-content-center">
                
                <Table striped borderless>
                <thead
                    className="text-white"
                    style={{ backgroundColor: "black" }}
                >
                    <tr>
                    <th scope="col">#</th>
                    <th scope="col">Description</th>
                    <th scope="col">Qty</th>
                    <th scope="col">Unit Price</th>
                    <th scope="col">Amount (Inc GST)</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                    <th scope="row">1</th>
                    <td>ACRAs Tickets</td>
                    <td>{order.tickets_count}</td>
                    <td>${(ticket_price)}</td>
                    <td>${(order.total_ticket_price)}</td>
                    </tr>
                    <tr>
                    <th scope="row">1</th>
                    <td>ACRAs After Party Tickets</td>
                    <td>{order.after_party_count}</td>
                    <td>${(after_party)}</td>
                    <td>${(order.total_after_party)}</td>
                    </tr>

                </tbody>
                </Table>
            </Row>
            <Row>
                <Col xs xl="8">

                </Col>
                <Col xs xl="3" >

                <ul className="nostyleli">
                    <li className="text-muted ms-3 mt-2">
                    <span class="text-black me-4">Sub-Total (ex-GST)</span>${(((Number(order.total_ticket_price) + Number(order.total_after_party)))/1.1).toFixed(2)}
                    </li>
                    <li className="text-muted ms-3 mt-2">
                    <span class="text-black me-4">GST(10%)</span>${(((Number(order.total_ticket_price) + Number(order.total_after_party)))*0.1).toFixed(2)}
                    </li>
                    {
                        (order.payment_type === "cc") &&
                            <li className="text-muted ms-3 mt-2">
                            <span class="text-black me-4">Credit Card Fee (1.84%)</span>${(Number(order.cc_total) - (Number(order.total_ticket_price) + Number(order.total_after_party))).toFixed(2)}
                        
                            </li>
                     }
                </ul>
                <p className="text-black float-start">
                    <span className="text-black me-3"> Total</span>
                    <span style={{ fontSize: "25px" }}>${(Number(order.total_ticket_price) + Number(order.total_after_party)).toFixed(2)}</span>
                </p>
                {
                (order.payment_type === "cc") &&
                <p className="text-black float-start">
                    <span className="text-black me-3"> Total(Credit Card)</span>
                    <span style={{ fontSize: "25px" }}>${(Number(order.cc_total)).toFixed(2)}</span>
                </p>
                }
                </Col>
            </Row>
            <hr />
            <Row>
                <Col xl="6">
                                <p>For further enquires, please contact <a href="mailto:events@commercialradio.com.au">events@commercialradio.com.au</a></p>
                                <p>Commercial Radio & Audio Ltd ABN: ABN 52 059 731 467</p>
                </Col>
                { (order.payment_type === "cc") ?
                <Col xs xl="3">
                   <h4>Pay via Credit Card</h4>
                    <Button
                        className="text-capitalize"
                        style={{ backgroundColor: "black" }}
                        onClick={()=>doClick("https://payment.commercialradio.au/checkout/acrastickets/?ticket_no=<ticket_no>&order=<order_id>&after_no=<after_no>",order.tickets_count,order.id,order.after_party_count)}
                    >
                        
                        Pay Now
                    </Button>
                    
                </Col>
                :
                <Col xs xl="3">
                <h4>Pay via Bank Transfer</h4>
                <p>For EFT please put your order number in the statements reference. <br />BSB: 062 203  ACCOUNT NO: 10011658</p>
                </Col>
                }
            </Row>
            </Card.Body>
        </Card>
        </>: null}
    </Container>
    </>
  );
};

export default Invoice;
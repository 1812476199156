import React, { useState, useEffect } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import Col from 'react-bootstrap/Col';

import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Stack from 'react-bootstrap/Stack';

import TableForm from './TableForm'
import { setLoading, getLoading} from "../slices/loading";


import Card from 'react-bootstrap/Card';
import { get_api_tables,set_table,save_import_excel, confirm_import_excel } from "../slices/ticket";
import { setMessage, showMessage } from "../slices/message";

import DataTable from './DataTable';


function ExcelImport() {
  const [successful, setSuccessful] = useState(false);
  const [document, setDocument] = useState({
        document: "",
    });

  const [data, setData] = useState();
  const [dataId, setDataId] = useState();
  const [dataLength, setDataLength] = useState();

  const dispatch = useDispatch()
  
  const handleConfirmImport = (e) =>{
    dispatch(setLoading(true))
    dispatch(confirm_import_excel({document_id: dataId}))
    .unwrap().then((response) => {
              dispatch(setLoading(false))
              dispatch(setMessage("Imported Contacts:" + response.length))
              dispatch(showMessage(true))
              dispatch(get_api_tables()).unwrap()
              .then((data) => {
                console.log(data)
                dispatch(set_table(data))
                
              })
              .catch((error) => {

                console.log(error)

              });
              
              dispatch(setLoading(false))

          })
          .catch((error) => {
              console.log(error)
              dispatch(setLoading(false))
              dispatch(setMessage("Error creating Orders. Please try again"))
              dispatch(showMessage(true))
          });

  }
  

  const handleDocumentChange = (e) => {
    let newData = { ...document };
    newData["document"] = e.target.files[0];
    setDocument(newData);
    dispatch(setLoading(true))
    dispatch(save_import_excel(newData))
    .unwrap()
          .then((data) => {
                dispatch(setLoading(false))
                setDataId(data.id)
                setData(data.data)
                setDataLength(data.length)
                
          })
          .catch((error) => {
            dispatch(setLoading(false))
            dispatch(setMessage("Error Importing Excel Spreadsheet. Please try again"))
            dispatch(showMessage(true))
          });
  }

  return (
    <Container>
        
        <Stack gap={3}>
            <Row>
                <Col>
                    <Stack gap={3}>
                    
                    
                    <Card>
                        <Card.Body>
                            <h3> ACRA Ticketing</h3>
                            <p>
                                Welcome to ACRA ticketing website.
                                To import an excel spreadsheet this tool. 
                                <ul>
                                    <li>The Excel spreedsheet should have first row firstname,lastname,email, cuisine,comments. Please use the template.</li>
                                    <li>Seats, ordering and tables will be filled out as the order of the spreadsheet</li>
                                    <li>Importing will not edit existing records. It will add additional seats. </li>
                                    <li>The process will not look for duplications. </li>
                                    <li>Incomplete details will not be added</li>                                     
                                    
                                </ul>
                                
                                <a href="/document/acra_ticket_template.xlsx"><Button variant="primary"> Download Template</Button>{' '}</a>
                                
                            </p>
                            
                        </Card.Body>
                        

                    </Card>
                    <Card>
                      <Card.Body>
                            <h3>Upload File</h3>
                            <p>
                                  <input type="file"
                                            name="document"
                                            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"  
                                            onChange={(e) => {handleDocumentChange(e)}}
                                            required/>


                            </p>

                      </Card.Body>

                    </Card>
                    {data && (
                        <Card>
                      
                            <Card.Body>
                                
                                <Stack gap={3}>
                                    <h3>Preview top 5</h3>
                                    <DataTable data={data} />
                                    <p>Total Tickets to Import:{dataLength}</p>
                                    <p>
                                    <Button class="btn"
                                        type="button"
                                        onClick={(e) => {handleConfirmImport(e)}}>Import All</Button>
                                    </p>
                                  </Stack>
                                    
                                
                              
                            </Card.Body>
                            

                        </Card>
                      )} 
                    </Stack>
                </Col>
            </Row>
           
      </Stack>
    </Container>
   
  )
}

export default ExcelImport
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from "react-redux";
import store from "./store";

import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

import Main from './components/Main'
import Account from './components/Account'
import Order from './components/Order'
import Ticketing from './components/Ticketing'
import RGTicketing from './components/RGTicketing'
import ExcelImport from './components/ExcelImport'
import UserRegistration from './components/UserRegistration'
import UserSignIn from './components/UserSignIn'
import Invoice from './components/Invoice'
import Order_Complete from './components/Order_Complete'
import AccountNotVerified from './components/AccountNotVerified'
import AdminSignIn from './components/AdminSignIn'
import TicketSearch from './components/TicketSearch';



const router = createBrowserRouter([
  {
    path: "/",
    element: <Main />,
    children:[
      {
        path: "",
        element: <RGTicketing />,
      },
      {
        path: "account",
        element: <Account />,
      },
      {
        path: "order",
        element: <Order />,
      },
      {
        path: "import",
        element: <ExcelImport />,
      },
      {
        path: "order/complete",
        element: <Order_Complete />,
      },
      
    ]
  },
  {
    path: "/account/unverified",
    element: <AccountNotVerified />,
  },
  
  {
    path: "/invoice",
    element: <Invoice />,
  },
  
  {
    path: "/signin",
    element: <UserSignIn/>,
  },
  {
    path: "/register",
    element: <UserRegistration/>,
  },
  {
    path: "/admin",
    element: <AdminSignIn/>,
  },
  {
    path: "/ticket/search",
    element: <TicketSearch/>
  },

]);


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
     <Provider store={store}>
      <RouterProvider router={router} />
     </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

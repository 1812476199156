import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

import { register } from "../slices/auth";
import { clearMessage } from "../slices/message";
import Spinner from 'react-bootstrap/Spinner';
import { setLoading, getLoading} from "../slices/loading";
import Row from 'react-bootstrap/Row';
import Image from 'react-bootstrap/Image';

function ShowHidePassword({ name }) {
  const [isVisible, setVisible] = useState(false);

  const toggle = () => {
    setVisible(!isVisible);
  };

  return (
    <div className="form-group">
      <input type={!isVisible ? "password" : "text"} name={name} required />
      <span className="icon" onClick={toggle}>
        {isVisible ? <span>Hide</span> : <span>Show</span>}
      </span>
    </div>
  );
}




const Register = () => {
  const [successful, setSuccessful] = useState(false);
  const [servermessage, setServermessage] = useState();
  const [successmsg, setSuccessmsg] = useState();

  const { message } = useSelector((state) => state.message);
  const isloading = useSelector(getLoading)
  const dispatch = useDispatch();

  const [isPassVisible, setPassVisible] = useState(false);

  useEffect(() => {
    dispatch(clearMessage());
  }, [dispatch]);

  var server_message = ""
  const initialValues = {
    name: "",
    email: "",
    password: "",
    company: "",
    address: "",
    city: "",
    state: "",
    postcode: ""
  };

  const toggle = () => {
    setPassVisible(!isPassVisible);
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required("This field is required!"),
    company: Yup.string()
      .required("This field is required!"),
    address: Yup.string()
      .required("This field is required!"),
    city: Yup.string()
      .required("This field is required!"),
    state: Yup.string()
      .required("This field is required!"),
    postcode: Yup.string()
      .required("This field is required!"),
    email: Yup.string()
      .email("This is not a valid email.")
      .required("This field is required!"),
    password: Yup.string()
      .test(
        "len",
        "The password must be between 6 and 40 characters.",
        (val) =>
          val &&
          val.toString().length >= 6 &&
          val.toString().length <= 40
      )
      .required("This field is required!"),
  });

  const handleRegister = (formValue) => {
    const { name, email, password,company,address,city,state,postcode } = formValue;

    const first_name = name.split(' ').slice(0, -1).join(' ');
    const last_name = name.split(' ').slice(-1).join(' ');


    const password1 = password
    const password2 = password

    //email, password1,password2, first_name,last_name,company
    setSuccessful(false);
    dispatch(setLoading(true))

    dispatch(register({ email, password1,password2, first_name,last_name,company,address,city,state,postcode }))
      .unwrap()
      .then(() => {
        setSuccessful(true);
        setServermessage("")
        dispatch(setLoading(false))
        setSuccessmsg("Thank you for registering for ACRA's Tickets. Our support staff will get in touch soon.")
        
      })
      .catch((error) => {
        setSuccessful(false);
        dispatch(setLoading(false))
        console.log(error)
        setServermessage(error)
      });
  };


  

  return (
    <div className="Auth-form-container">
            <div >
                {servermessage && (
                    Object.keys(servermessage).map((key,i) => (
                        <div className="form-group">
                        
                            <div
                                className={successful ? "alert alert-success" : "alert alert-danger"}
                                role="alert"
                            >
                               {servermessage[key]}
                            </div>
                        
                        </div>
                    ))
                )}
                 
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleRegister}
                    >
                    <Form className="Auth-form">
                        {successmsg && (
                            <div className="form-group">
                                <div
                                        className={successful ? "alert alert-success" : "alert alert-danger"}
                                        role="alert"
                                    >
                                        {successmsg}
                                </div>
                            </div>

                        )}
                        {!successful && (
                        <div className="Auth-form-content">
                            <div style={{textAlign:"center"}}>
                             <Image src="static/images/ACRA_100Years_Lockup.png" fluid />
                            </div>
                            <h3 className="Auth-form-title">Ticket Portal Registration</h3>
                            <p style={{textAlign:"center"}}></p>
                            <div className="form-group  mt-3">
                            <label htmlFor="name">Name</label>
                            <Field name="name" type="text" className="form-control" placeholder="eg. John Doe" />
                            <ErrorMessage
                                name="name"
                                component="div"
                                className="alert alert-danger"
                            />
                            </div>

                            <div className="form-group  mt-3">
                            <label htmlFor="email">Email</label>
                            <Field name="email" type="email" className="form-control" placeholder="Enter email address" />
                            <ErrorMessage
                                name="email"
                                component="div"
                                className="alert alert-danger"
                            />
                            </div>

                            <div className="form-group  mt-3">
                              <label htmlFor="Company">Company</label>
                              <Field name="company" type="text" className="form-control" placeholder="Company"/>
                              <ErrorMessage
                                  name="company"
                                  component="div"
                                  className="alert alert-danger"
                              />
                            </div>
                            <div className="form-group  mt-3">
                            <label htmlFor="Company">Street Address</label>
                            <Field name="address" type="text" className="form-control" placeholder="Address"/>
                            <ErrorMessage
                                name="address"
                                component="div"
                                className="alert alert-danger"
                            />
                            </div>
                            <div className="form-group  mt-3">
                            <label htmlFor="city">City</label>
                            <Field name="city" type="text" className="form-control" placeholder="City"/>
                            <ErrorMessage
                                name="city"
                                component="div"
                                className="alert alert-danger"
                            />
                            </div>
                            <Row className="mb-3">
                            <div className="form-group  mt-3 col">
                            <label htmlFor="state">State</label>
                            <Field name="state" type="text" className="form-control" placeholder="State"/>
                            <ErrorMessage
                                name="state"
                                component="div"
                                className="alert alert-danger"
                            />
                            </div>
                            <div className="form-group  mt-3 col">
                            <label htmlFor="postcode">Postcode</label>
                            <Field name="postcode" type="text" className="form-control" placeholder="State"/>
                            <ErrorMessage
                                name="postcode"
                                component="div"
                                className="alert alert-danger"
                            />
                            
                            </div>
                            </Row>
                            <div className="form-group  mt-3">
                            <label htmlFor="password">Password</label>
                            <Field
                                name="password"
                                type={!isPassVisible ? "password" : "text"}
                                className="form-control"
                                placeholder="Enter password"
                            />
                            <ErrorMessage
                                name="password"
                                component="div"
                                className="alert alert-danger"
                            />
                              <span className="icon" onClick={toggle}>
                                  {isPassVisible ? <span>Hide</span> : <span>Show</span>}
                              </span>
                            </div>

                            <div className="form-group">
                                <div className="d-grid gap-2 mt-3">
                                    <button type="submit" className="btn btn-primary">
                                    { isloading ? 
                                    <Spinner
                                        as="span"
                                        animation="grow"
                                        size="sm"
                                        aria-hidden="true"
                                    />: null
                                    }
                                    Register
                                    </button>
                                </div>
                            </div>
                        </div>
                        )}
                        <div className="d-grid gap-2 mt-3" style={{textAlign:'center'}} >Already registered?  <a href='/signin'>Sign In </a> </div>
                    </Form>
                    
                </Formik>

                

                
            </div>
    </div>
  );
};

export default Register;
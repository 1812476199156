import * as React from "react"
import Button from "react-bootstrap/Button"
import { useDispatch, useSelector } from "react-redux";
import {
  isNavigationKey,
  getCellProperty,
  isAlphaNumericKey,
  keyCodes
} from "@silevis/reactgrid"

import { update_table, remove_seat, delete_ticket } from "../../slices/ticket";


export class ButtonCellTemplate {
  wasEscKeyPressed = false


  getCompatibleCell(uncertainCell) {
    const text = getCellProperty(uncertainCell, "text", "string")
    const seat = getCellProperty(uncertainCell, "seat", "number")
    const seat_obj = getCellProperty(uncertainCell, "seat_obj", "object")
    const value = parseFloat(text)
    return { ...uncertainCell, text, seat, seat_obj, value }
  }

  handleCompositionEnd(cell, eventData) {
    return { cell: { ...cell, text: eventData }, enableEditMode: true }
  }

  update(cell, cellToMerge) {
    return this.getCompatibleCell({ ...cell, text: cellToMerge.text })
  }

  render(cell, isInEditMode, onCellChanged) {
    return (
      <div style={{padding: '1px'}}>
        {        
          
          (cell.text != "" ?
          
            <Button variant="warning" onClick={e => onCellChanged(this.getCompatibleCell({ ...cell, text: e.currentTarget.value }), true)}>{cell.text}</Button>: <></>
         
          )
          
        }
      </div>
    )
  }
}

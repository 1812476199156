import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";


import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Stack from 'react-bootstrap/Stack';
import Card from 'react-bootstrap/Card';
import Badge from 'react-bootstrap/Badge';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Form from 'react-bootstrap/Form';


import { setLoading, getLoading} from "../slices/loading";

import { get_api_tables, set_table,add_table,getTables,save_api_tables,save_api_order, remove_seat } from "../slices/ticket";
import { setMessage, showMessage } from "../slices/message";
import ticketService from "../services/ticket.service";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileInvoice, faCartShopping } from '@fortawesome/free-solid-svg-icons'
import { useNavigate } from "react-router-dom";

const Order = () => {
  const [successful, setSuccessful] = useState(false);
  const [orders, setOrders] = useState([]);


  const dispatch = useDispatch();
  const navigate = useNavigate();

  const doClick = (url,ticket_no, order) => {
    let new_url = url.replace("<order_id>", order);
    new_url = new_url.replace("<ticket_no>", ticket_no);
    // get the data from the api
    //navigate(new_url)
    window.location =new_url
   
  }

  

    const handlePaymentTypeChange = async (e) => {
        const {id, value} = e.currentTarget;
        console.log(id, value)
        setOrders(
            orders.map((item) => {
              if (item.id === Number(id)) {
                
                return { ...item, payment_type: value };
              } else {
                return item;
              }
            })
          );
        
          ticketService.putOrder(Number(id),{payment_type: value})
        

         

    };

  const handleOrderSave = () => {

    dispatch(setLoading(true))
    dispatch(save_api_order())
    .unwrap()
          .then((message) => {
                dispatch(setLoading(false))
                dispatch(setMessage(message))
                dispatch(showMessage(true))
                const fetchData = async () => {
                    // get the data from the api
                    const data = await ticketService.getOrders()
                    // convert the data to json
               
                    // set state with the result
                    setOrders(data);
                  }
                  fetchData()
                  dispatch(get_api_tables()).unwrap()
                    .then((data) => {

                    dispatch(set_table(data))
                    
                    })
                    .catch((error) => {

                    console.log(error)

                    });
                
          })
          .catch((error) => {
            console.log(error)
            dispatch(setLoading(false))
            dispatch(setMessage("Error creating Orders. Please try again"))
            dispatch(showMessage(true))
          });

  }



  useEffect(() => {
    const fetchData = async () => {
        // get the data from the api
        const data = await ticketService.getOrders()
        // convert the data to json
   
        // set state with the result
        setOrders(data);
      }
      fetchData()
    // make sure to catch any error
    .catch(console.error);;
  }, []);

  const buttons = [
    { icon: faFileInvoice, id:'invoice',tooltip: "View Invoice", ClickURL:"/invoice?order=<order_id>"},
    //{ icon: faCartShopping, id:'creditcard', tooltip: "Pay Invoice by CC", ClickURL:"https://payment.commercialradio.au/checkout/acrastickets/?ticket_no=<ticket_no>&order=<order_id>"}
    ]

  return (
   

    <Container>
        <Stack gap={3}>
            <Row>
                <Col>
                    
                    <Card>  
                        <Card.Body>
                            <h3> ACRA Ticket Orders</h3>
                            <p>Please pay for your ACRA tickets using the online payment portal or bank transfer.</p>
                            <p>Order(s) that have been created are shown below.</p>

                            <Button variant="info" onClick={()=>handleOrderSave()} >Create order to pay for unpaid tickets</Button>{' '}
                        </Card.Body>
                    </Card>

                </Col>
            </Row>
            <Row>
                <Stack gap={3}>
                {
                    orders.map((order,i) => {
                        var paid_status_bg = "danger"
                        var paid_status = "Unpaid"

                        if (order.paid){
                            paid_status_bg = "success"
                            paid_status = "Paid"
                        }

                        return(
                            <Card key={i}>
                                <Card.Body>
                                    <Row xs={1} md={2}>
                                        <Col > <Badge pill bg={paid_status_bg}>{paid_status}</Badge>{' '} Order Number: {order.id} | Total Tickets: {order.tickets_count} | After Party Tickets: {order.after_party_count}</Col>
                                        <Col>  
                                                <Stack className="justify-content-end" direction="horizontal" gap={4}>
                                                    
                                                    {
                                                        ((order.payment_type === null) ?
                                                        <><Form.Select id={order.id }onChange={(e)=>handlePaymentTypeChange(e)}>
                                                            <option value="">Select Payment Type</option>
                                                            <option value="cc">Credit Card</option>
                                                            <option value="bank">Bank Transfer</option>
                                                        </Form.Select></>
                                                        :
                                                        buttons.map((button, i ) => {
                                                            return(
                                                                <OverlayTrigger
                                                                key={i}
                                                                placement="bottom"
                                                                overlay={<Tooltip id="button-tooltip-2">{button.tooltip}</Tooltip>}
                                                                >
                                                                    { button.id === 'creditcard' & order.paid === true ?
                                                                        <></>
                                                                        :
                                                                        <>
                                                                        <Badge pill bg="success">{order.payment_type}</Badge> {' '} <FontAwesomeIcon  icon={button.icon}  size="2x" onClick={()=>doClick(button.ClickURL,order.total_tickets,order.id)}/>
                                                                        </>
                                                                    }
                                                                </OverlayTrigger>
                                                            )
                                                        }))
                                                    }
                                                    
                                                </Stack>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        )

                    })
                }
                </Stack>
            </Row>

        </Stack>
            
    </Container>
  );
};

export default Order;